// ** Icon import
import HomeOutline from 'mdi-material-ui/HomeOutline'
import GroupIcon from '@mui/icons-material/Group'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import LocalAtmIcon from '@mui/icons-material/LocalAtm'
import MenuIcon from '@mui/icons-material/Menu'
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined'
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined'
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined'
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined'
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined'
import MarkEmailUnreadOutlinedIcon from '@mui/icons-material/MarkEmailUnreadOutlined';

const navigation = () => {
  return [
    {
      icon: HomeOutline,
      title: 'Dashboard',
      path: '/dashboards/crm'
    },
    // {
    //   sectionTitle: 'Apps & Pages'
    // },
    {
      icon: ListAltOutlinedIcon,
      title: 'Register Users List',
      path: 'apps/register'
    },
    {
      icon: ListAltOutlinedIcon,
      title: 'Seller List',
      path: 'apps/seller'
    },
    {
      icon: QuestionMarkOutlinedIcon,
      title: 'Seller Questions',
      path: 'seller/questions'
    },
    {
      icon: ListAltOutlinedIcon,
      title: 'Buy Request List',
      path: 'buy/request'
    },
    {
      icon: ListAltOutlinedIcon,
      title: 'Business List',
      path: 'admin/my/companies/list'
    },
    {
      icon: AccountBalanceOutlinedIcon,
      title: 'Admin Bank Account ',
      path: 'admin/bank-details'
    },
    {
      icon: TextsmsOutlinedIcon,
      title: 'Comment',
      path: 'admin/comment'
    },
    {
      icon: NotificationsOutlinedIcon,
      title: 'Notification',
      path: 'admin/notification'
    },
    {
      icon: NotificationsOutlinedIcon,
      title: 'Holding Change Request',
      path: 'admin/holding/change/request/list'
    },
    {
      icon: MarkEmailUnreadOutlinedIcon,
      title: 'OTP Content',
      path: 'admin/otp-content'
    }
    // {
    //   title: 'User',
    //   icon: AccountOutline,
    //   children: [
    //     {
    //       icon: BoyIcon,
    //       title: 'Patient',
    //       path: 'apps/user/patient'
    //     },
    //     {
    //       icon: WheelchairPickupIcon,
    //       title: 'Caretaker',
    //       path: 'apps/user/caretaker'
    //     },
    //     {
    //       icon: Diversity3Icon,
    //       title: 'DoctorTeam',
    //       path: 'apps/user/doctorteam'
    //     },
    //   ]
    // },
    // {
    //   icon: AccountGroupOutline,
    //   title: 'Group',
    //   path: 'apps/user/groupdr'
    // },
    // {
    //   icon: FileDocumentOutline,
    //   title: 'Reports',
    //   path: 'apps/user/reports'
    // },
  ]
}

export default navigation
