import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { axiosInstance } from '../../../network/adapter'
import { ApiEndPoints, MEDIA_URL } from '../../../network/endpoints'
import { Box, Button, Card, CardContent, Chip, Divider, Grid, Paper, Typography } from '@mui/material'
import FallbackSpinner from 'src/@core/components/spinner'
import moment from 'moment'
import ShareHolderTable from 'src/component/investByBusiness/shareholderTable'
import BusinessStatusDialog from 'src/component/dialog/businessStatusDialog'
import DataTable from 'src/component/dataTable'
import { DefaultPaginationSettings } from 'src/constants/defaultPaginationSettings'
import { getFileType, getFileTypeIcon } from 'src/constants/constants'

const TextField_Style = {
  background: '#FFF',
  textAlign: 'start',
  fontWeight: 'bold',
  wordBreak: 'break-word',
  textTransform: 'capitalize',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: '14px' // Remove the border of the outlined input
    }
  },
  '&:hover fieldset': {
    borderRadius: '14px' // Remove the border on hover
  },
  '&.Mui-focused fieldset': {
    borderRadius: '14px' // Remove the border when focused
  }
}

const Document_Box_Style = {
  gap: '5px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '10px',
  border: '1px solid black',
  width: '100%',
  height: '56px',
  flexShrink: 0,
  strokeWidth: '1px',
  stroke: '#303A4B',
  '&: hover': { border: '3px solid black' }
}

const CreateSeller_Text_Style = {
  color: '#303A4B',
  fontSize: '28px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  textTransform: 'capitalize'
}

const tableStyle = {
  border: '2px solid rgba(80, 80, 80, 0.22)',
  borderRadius: '15px',
  '& .MuiDataGrid-columnHeaders': {
    color: 'primary.main',
    fontSize: '16px',
    fontWeight: 900,
    borderRadius: '20px'
  },
  '& .MuiDataGrid-row:hover': {
    borderRadius: '50px'
  },
  '& .MuiDataGrid-rowFirst': {
    marginLeft: '5px'
  },
  '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
    outline: 'none'
  }
}

function BusinessDetails() {
  const params = useParams()
  const { id } = params

  const [businessDetails, setBusinessDetails] = useState('')
  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(true)
  const [tableLoading, setTableLoading] = useState(true)
  const [open, setOpen] = useState(false)

  console.log('businessDetails', businessDetails)

  const getUBusinessId = useCallback(async () => {
    setLoading(true)
    await axiosInstance
      .get(ApiEndPoints.MY_BUSINESS.business_by_id(id))
      .then(response => {
        console.log('response.data Details', response?.data)
        let StatusValue = response?.data?.business[0]?.company_status
        let statusColor = ''
        if (StatusValue === 'pending') {
          statusColor = 'yellow'
        } else if (StatusValue === 'rejected') {
          statusColor = 'error'
        } else if (StatusValue === 'approved') {
          statusColor = 'success'
        } else if (StatusValue === 'expired') {
          statusColor = 'error'
        } else if (StatusValue === 'remove') {
          statusColor = 'error'
        } else if (StatusValue === 'renewal required') {
          statusColor = 'yellow'
        } else if (StatusValue === 'expire') {
          statusColor = 'error'
        }
        setBusinessDetails({ ...response?.data?.business[0], color: statusColor })
      })
      .catch(error => {
        setLoading(false)
        if (error.response) {
          console.error('API error:', error.response.status, error.response.data.data)
        } else if (error.request) {
          console.error('No response received from the server')
        } else {
          console.error('Error:', error.message)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [id, setBusinessDetails])

  const getWhoUsedThisBusinessDetails = useCallback(() => {
    setTableLoading(true)
    axiosInstance
      .get(ApiEndPoints.MY_BUSINESS.who_used_this_business(id))
      .then(response => response.data)
      .then(response => {
        console.log('getWhoUsedThisBusinessDetails', response)
        const dataWithUniqeId = response.business.map((item, index) => ({
          ...item,
          id: index
        }))
        setRows(dataWithUniqeId)
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        setTableLoading(false)
      })
  }, [id])

  useEffect(() => {
    getUBusinessId()
    getWhoUsedThisBusinessDetails()
  }, [getUBusinessId, getWhoUsedThisBusinessDetails])

  const handleStatusDialog = () => {
    setOpen(prev => !prev)
  }

  const handleDocumentOpen = path => {
    window.open(`${MEDIA_URL}${path}`)
  }

  const columns = [
    {
      field: 'seller_name',
      headerName: 'Seller name',
      minWidth: 300,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ row }) => {
        return (
          <div>
            <Typography
              sx={{
                width: '100%', // Set the width to 100%
                whiteSpace: 'nowrap', // Prevent text from wrapping
                overflow: 'hidden', // Hide overflowing text
                textOverflow: 'ellipsis'
              }}
            >
              {row?.userName}
            </Typography>
          </div>
        )
      }
    },
    {
      field: 'company',
      headerName: 'Company',
      minWidth: 300,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: 'left',
      align: 'left',
      renderCell: ({ row }) => {
        return (
          <div>
            <Typography
              textTransform='capitalize'
              sx={{
                width: '100%', // Set the width to 100%
                whiteSpace: 'nowrap', // Prevent text from wrapping
                overflow: 'hidden', // Hide overflowing text
                textOverflow: 'ellipsis'
              }}
            >
              {row?.company_name}
            </Typography>
          </div>
        )
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 250,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: 'left',
      align: 'left',
      renderCell: ({ row }) => {
        return (
          <div>
            <Typography textTransform='capitalize'>{row?.status}</Typography>
          </div>
        )
      }
    },
    {
      field: 'buyrequeststatus',
      headerName: 'Buy Request Status',
      minWidth: 250,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: 'left',
      align: 'left',
      renderCell: ({ row }) => {
        console.log('buyRequestStatus', row)
        return (
          <div>
            <Typography textTransform='capitalize'>{row?.buyRequestStatus}</Typography>
          </div>
        )
      }
    }
  ]

  return (
    <>
      <Grid container spacing={6} sx={{ bgcolor: '#F4F5FA' }}>
        <Grid item xs={12}>
          {loading ? (
            <FallbackSpinner height={'80vh'} />
          ) : (
            <Card>
              <Box p={4} display='flex' gap={2} justifyContent={'space-between'}>
                <Box
                  display={'flex'}
                  sx={{
                    justifyContent: 'center'
                  }}
                >
                  <Typography sx={{ ...CreateSeller_Text_Style }}>Business Details</Typography>
                  <Chip
                    color={businessDetails?.color}
                    label={businessDetails?.company_status}
                    sx={{ textTransform: 'capitalize' }}
                    size='small'
                  ></Chip>
                </Box>
                <Box>
                  <Button
                    sx={{
                      backgroundColor: '#007167',
                      color: '#fff',
                      '&:hover': {
                        backgroundColor: '#007167',
                        color: '#fff'
                      }
                    }}
                    onClick={handleStatusDialog}
                  >
                    Status
                  </Button>
                </Box>
              </Box>
              <Divider sx={{ m: 0 }} />
              {businessDetails?.nationality === 'saudi' && (
                <CardContent>
                  <Grid container spacing={6}>
                    <Grid item xs={12} sm={12}>
                      <Paper elevation={5} sx={{ padding: 3, borderRadius: '25px' }}>
                        <Box display={'flex'} flexDirection={'column'} gap={2}>
                          <Box display={'flex'} gap={2}>
                            <Typography textAlign={'left'} sx={{ color: '#00000099', textTransform: 'capitalize' }}>
                              CR Name:
                            </Typography>
                            <Typography sx={{ ...TextField_Style }}>{businessDetails?.cr_name || '--'}</Typography>
                          </Box>
                          <Divider />
                          <Box display={'flex'} gap={2}>
                            <Typography textAlign={'left'} sx={{ color: '#00000099', textTransform: 'capitalize' }}>
                              CR ID:
                            </Typography>
                            <Typography sx={{ ...TextField_Style }}>{businessDetails?.cr_id || '--'}</Typography>
                          </Box>
                          <Divider />
                          {businessDetails?.cr_share_gross_amount && (
                            <div>
                              <Box display={'flex'} gap={2}>
                                <Typography textAlign={'left'} sx={{ color: '#00000099', textTransform: 'capitalize' }}>
                                  Cr share gross_amount:
                                </Typography>
                                <Typography sx={{ ...TextField_Style }}>
                                  {(businessDetails?.cr_share_gross_amount !== 'undefined' &&
                                    businessDetails?.cr_share_gross_amount) ||
                                    '-'}
                                </Typography>
                              </Box>
                              <Divider />
                            </div>
                          )}
                          {businessDetails?.cr_share_unit_quantity && (
                            <div>
                              <Box display={'flex'} gap={2}>
                                <Typography textAlign={'left'} sx={{ color: '#00000099', textTransform: 'capitalize' }}>
                                  Cr share gross_amount:
                                </Typography>
                                <Typography sx={{ ...TextField_Style }}>
                                  {(businessDetails?.cr_share_unit_quantity !== 'undefined' &&
                                    businessDetails?.cr_share_unit_quantity) ||
                                    '-'}
                                </Typography>
                              </Box>
                              <Divider />
                            </div>
                          )}
                          <Box display={'flex'} gap={2}>
                            <Typography
                              textAlign={'left'}
                              sx={{
                                color: '#00000099',
                                textTransform: 'capitalize'
                              }}
                            >
                              Company Headquarter:
                            </Typography>
                            <Typography sx={{ ...TextField_Style }}>{businessDetails?.nationality}</Typography>
                          </Box>
                          <Divider />
                          <Box display={'flex'} gap={2}>
                            <Typography textAlign={'left'} sx={{ color: '#00000099' }}>
                              Created At :
                            </Typography>
                            <Typography sx={{ ...TextField_Style }}>
                              {moment(businessDetails.createdAt).format('ll') || '--'}
                            </Typography>
                          </Box>
                        </Box>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {businessDetails?.parties?.length > 0 && (
                        <div>
                          <Typography
                            textAlign={'left'}
                            ml={'20px'}
                            mb={2}
                            color={'#3a3541de'}
                            fontSize={20}
                            fontWeight={800}
                          >
                            Shareholders{' '}
                          </Typography>
                          <ShareHolderTable
                            shareHolderData={
                              businessDetails?.parties &&
                              businessDetails?.parties?.filter(item => item?.relation?.id == 2)
                            }
                          />
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      {businessDetails?.parties?.length > 0 && (
                        <div>
                          <Typography
                            textAlign={'left'}
                            ml={'20px'}
                            mb={2}
                            color={'#3a3541de'}
                            fontSize={20}
                            fontWeight={800}
                          >
                            Managers{' '}
                          </Typography>
                          <ShareHolderTable
                            shareHolderData={
                              businessDetails?.parties &&
                              businessDetails?.parties?.filter(item => item?.relation?.id == 24)
                            }
                          />
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
              )}
              {businessDetails?.nationality === 'non-saudi' && (
                <CardContent>
                  <Grid container spacing={5}>
                    <Grid item xs={12} sm={6}>
                      <Paper elevation={5} sx={{ padding: 3, borderRadius: '25px' }}>
                        <Box display='flex' justifyContent='center' mb='20px'>
                          {businessDetails?.company_logo && (
                            <img
                              src={`${MEDIA_URL}${businessDetails?.company_logo}`}
                              style={{
                                height: '50%',
                                maxHeight: '400px',
                                maxWidth: '400px',
                                minHeight: '100px'
                              }}
                              alt={`${businessDetails?.company_name.slice(0, 2)}`}
                            />
                          )}
                        </Box>
                        <Box display={'flex'} flexDirection={'column'} gap={2}>
                          <Box display={'flex'} gap={2}>
                            <Typography textAlign={'left'} sx={{ color: '#00000099', textTransform: 'capitalize' }}>
                              Company Name:
                            </Typography>
                            <Typography sx={{ ...TextField_Style }}>{businessDetails.company_name || '--'}</Typography>
                          </Box>
                          <Divider />
                          <Box display={'flex'} gap={2}>
                            <Typography
                              textAlign={'left'}
                              sx={{
                                color: '#00000099',
                                textTransform: 'capitalize'
                              }}
                            >
                              Company Headquarter:
                            </Typography>
                            <Typography sx={{ ...TextField_Style }}>
                              {businessDetails?.nationality === 'non-saudi'
                                ? `Free Zone Area - ${businessDetails?.free_zone_area}`
                                : businessDetails?.free_zone_area}
                            </Typography>
                          </Box>
                          <Divider />{' '}
                          <Box display={'flex'} gap={2}>
                            <Typography textAlign={'left'} sx={{ color: '#00000099' }}>
                              Expiry Date :
                            </Typography>
                            <Typography sx={{ ...TextField_Style }}>
                              {moment(businessDetails.expiry_date).format('ll') || '--'}
                            </Typography>
                          </Box>
                        </Box>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {businessDetails?.documentFiles?.length > 0 && (
                        <Grid item xs={12} p={2}>
                          <Typography textAlign={'left'}>Uploaded Documents</Typography>
                          <Grid container spacing={1}>
                            {businessDetails?.documentFiles?.map((item, index) => {
                              const fileType = getFileType(item)
                              return (
                                <Grid item xs={4} key={index} sx={{ cursor: 'pointer' }}>
                                  <Box sx={{ ...Document_Box_Style }} onClick={() => handleDocumentOpen(item)}>
                                    {getFileTypeIcon(fileType)}
                                    <Typography alignSelf={'center'}>
                                      {fileType.toUpperCase()} {index + 1}
                                    </Typography>
                                  </Box>
                                </Grid>
                              )
                            })}
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Typography sx={{ fontWeight: 700 }}>Who Used this Company</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <DataTable
                        autoHeight
                        rows={rows}
                        columns={columns}
                        tableStyle={tableStyle}
                        loading={tableLoading}
                        pageSizeOptions={DefaultPaginationSettings.ROWS_PER_PAGE_OPTION_FOR_LARGE_DATA}
                        hideFooter={true}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              )}
            </Card>
          )}
        </Grid>
      </Grid>
      <BusinessStatusDialog
        isOpen={open}
        toggle={handleStatusDialog}
        businessID={id}
        businessStatus={businessDetails.company_status}
        getUBusinessId={getUBusinessId}
      />
    </>
  )
}
export default BusinessDetails
