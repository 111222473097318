import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { DefaultPaginationSettings } from "src/constants/defaultPaginationSettings";

const DataTable = ({
  rows,
  columns,
  tableStyle,
  handleCellClick,
  loading,
  pageSize,
  setLimit,
  setPage,
  totalCount,
  pageSizeOptions,
  hideFooter
}) => {
  if (!rows || !columns) {
    return <div>Loading...</div>;
  }

  return (
    <Box
      sx={{
        width: "100%",
        overflowX: "auto",
      }}
    >
      <DataGrid
        // PAGINATION SECTION STARTED
        pagination
        paginationMode="server"
        pageSizeOptions={pageSizeOptions}
        // rowHeight={DefaultPaginationSettings.ROW_HEIGHT}
        rowCount={totalCount}
        getRowHeight={() => 'auto'}
        onPaginationModelChange={(paginationModel) => {
          setLimit(paginationModel.pageSize);
          setPage(paginationModel.page);
        }}
        initialState={{
          pagination: { paginationModel: { pageSize: pageSize } },
        }}
        // PAGINATION SECTION ENDED
        disableRowSelectionOnClick={true}
        disableDensitySelector={true}
        disableVirtualization={true}
        rowSelection
        onCellClick={handleCellClick}
        getCellClassName={(params) => {
          if (handleCellClick) {
            if (!["actions"].includes(params.field)) return "cursor-pointer";
            return "";
          }
        }}
        density="standard"
        autoHeight
        rows={rows}
        columns={columns}
        loading={loading}
        sx={tableStyle}
        hideFooter={hideFooter}
      />
    </Box>
  );
};

export default DataTable;
