import axios from 'axios'
import authConfig from '../configs/auth'



export const axiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
    "authorization": `Bearer ${localStorage.getItem(authConfig.storageTokenKeyName)}`,
  },
});

// You can set default headers like Content-Type here
axiosInstance.defaults.headers.common['Content-Type'] = 'application/json';

// Add a request interceptor to set the authorization header
axiosInstance.interceptors.request.use((config) => {
  // Get the token from localStorage and set the authorization header
  const token = localStorage.getItem(authConfig.storageTokenKeyName);
  if (token) {
    config.headers['authorization'] = `Bearer ${token} `;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});