// export const API_BASE_URL = "http://192.168.29.195:8070";
// export const API_BASE_URL = 'http://localhost:8070'
export const API_BASE_URL = 'https://api.thanawi.io'
// export const API_BASE_URL = 'https://dev.api.thanawi.io'

export const MEDIA_URL = 'https://dqamw6n6ttqcg.cloudfront.net'
// export const MEDIA_URL = 'https://dwjjrp855v9wk.cloudfront.net'

export const ApiEndPoints = {
  AUTH: {
    login: `${API_BASE_URL}/api/v1/admin/auth/login`,
    me: `${API_BASE_URL}/api/v1/admin/user/me`,
    forgot_password: `${API_BASE_URL}/api/v1/admin/auth/forgot-password`
  },
  GET_DASHBOARD_COUNTS: {
    get_dashboard_counts: `${API_BASE_URL}/api/v1/admin/dashboard/stats`
  },
  USER_REGISTER: {
    get_user: `${API_BASE_URL}/api/v1/admin/user`
  },
  USER_REGISTER_VIEW: {
    get_view: `${API_BASE_URL}/api/v1/admin/user/`
  },
  USER_REGISTER_DELETE: {
    deleteuser: userID => `${API_BASE_URL}/api/v1/admin/user/${userID}`
  },
  USER_DETAILS_BY_ID: {
    get_user_by_id: userId => `${API_BASE_URL}/api/v1/admin/user/${userId}`
  },
  SELLER_DETAILS: {
    get_seller_list: `${API_BASE_URL}/api/v1/admin/seller`,
    get_seller_by_id: _id => `${API_BASE_URL}/api/v1/admin/seller/${_id}`,
    seller_count: `${API_BASE_URL}/api/v1/admin/seller/counts`
  },

  CONTRACT: {
    get_all_contracts: `${API_BASE_URL}/api/v1/admin/contracts/`,
    get_contract_by_id: _id => `${API_BASE_URL}/api/v1/admin/contracts/${_id}`,
    change_contract_status: _id => `${API_BASE_URL}/api/v1/admin/contracts/${_id}/status`,
    who_buy_this_stocks: contractId => `${API_BASE_URL}/api/v1/admin/contracts/${contractId}/buyers`
  },

  SELLER_QUESTIONS: {
    create_questions: `${API_BASE_URL}/api/v1/admin/question`,
    update_questions: id => `${API_BASE_URL}/api/v1/admin/question/${id}`,
    get_questions: `${API_BASE_URL}/api/v1/admin/question`,
    delete_questions: id => `${API_BASE_URL}/api/v1/admin/question/${id}`
  },
  BUY_REQUEST: {
    get_buy_request_list: `${API_BASE_URL}/api/v1/admin/buy-requests`,
    approved_buy_request: buyRequestId =>
      `${API_BASE_URL}/api/v1/admin/buy-requests/${buyRequestId}/status/approve-payment-verification`,
    reject_buy_request: buyRequestId =>
      `${API_BASE_URL}/api/v1/admin/buy-requests/${buyRequestId}/status/reject-payment-verification`
  },
  BANK_DETAILS: {
    get_admin_bank_details: `${API_BASE_URL}/api/v1/admin/platform/payment-details`,
    update_bank_details: `${API_BASE_URL}/api/v1/admin/platform/payment-details`
  },
  NOTIFICATIONS: {
    get_notifications: `${API_BASE_URL}/api/v1/admin/notifications`,
    sent_fcm_token: `${API_BASE_URL}/api/v1/admin/notifications/token`,
    get_unread_notifications_count: `${API_BASE_URL}/api/v1/admin/notifications/unread/count`,
    read_notifications: `${API_BASE_URL}/api/v1/admin/notifications/read`
  },
  COMMENTS: {
    get_comments: (buyRequestId, channel) =>
      `${API_BASE_URL}/api/v1/admin/buy-requests/${buyRequestId}/comment/${channel}`
  },
  HOLDING_REQUEST: {
    get_holding_change_request: `${API_BASE_URL}/api/v1/admin/stock-holding-requests`,
    change_holding_request_status: stockHoldingRequestId =>
      `${API_BASE_URL}/api/v1/admin/stock-holding-requests/${stockHoldingRequestId}/status`
  },
  MY_BUSINESS: {
    business_list: `${API_BASE_URL}/api/v1/admin/business`,
    business_by_id: id => `${API_BASE_URL}/api/v1/admin/business/get-business/${id}`,
    who_used_this_business: id => `${API_BASE_URL}/api/v1/admin/business/get-business-detail/${id}`,
    business_update: id => `${API_BASE_URL}/api/v1/admin/business/business-status/${id}`
  },
  OTP_CONTENT: {
    get_otp_content: templateType => `${API_BASE_URL}/api/v1/admin/sms-template/${templateType}`,
    update_otp_content: id => `${API_BASE_URL}/api/v1/admin/sms-template/update/${id}`
  }
}
