// import { LoadingButton } from '@mui/lab'
// import {
//   Button,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   FormControl,
//   InputLabel,
//   MenuItem,
//   Select,
//   TextField
// } from '@mui/material'
// import { useEffect, useState } from 'react'
// import toast from 'react-hot-toast'
// import { axiosInstance } from 'src/network/adapter'
// import { ApiEndPoints } from 'src/network/endpoints'

// function BusinessStatusDialog({ isOpen, toggle, businessID, businessStatus, getUBusinessId }) {
//   const [status, setStatus] = useState('')
//   const [notes, setNotes] = useState('')
//   const [loading, setLoading] = useState(false)

//   useEffect(() => {
//     if (isOpen) {
//       setStatus(businessStatus?.status || '')
//       setNotes(businessStatus?.notes || '')
//     }
//   }, [businessStatus, isOpen])

//   const UpdateBusinessStatus = () => {
//     setLoading(true)
//     let payload = {
//       company_status: status,
//       description: notes
//     }
//     axiosInstance
//       .put(ApiEndPoints.MY_BUSINESS.business_update(businessID), payload)
//       .then(response => response.data)
//       .then(response => {
//         toast.success(response.message)
//         setLoading(false)
//         getUBusinessId()
//         toggle()
//       })
//       .catch(error => {
//         console.error(error)
//         toast.error(error.response.data.message)
//         setLoading(false)
//       })
//   }

//   return (
//     <>
//       <Dialog open={isOpen} maxWidth='sm' fullWidth>
//         <DialogTitle>Status</DialogTitle>
//         <DialogContent>
//           <FormControl fullWidth margin='normal'>
//             <InputLabel id='startup-company-label'>Status</InputLabel>
//             <Select
//               labelId='startup-company-label'
//               id='startup-company-select'
//               label='Status'
//               value={status}
//               onChange={e => setStatus(e.target.value)}
//             >
//               {/* pending, approved, rejected */}
//               <MenuItem value={'approved'} sx={{ textTransform: 'capitalize' }}>
//                 Approved
//               </MenuItem>
//               <MenuItem value={'rejected'} sx={{ textTransform: 'capitalize' }}>
//                 Rejected
//               </MenuItem>
//             </Select>
//           </FormControl>
//           {status === 'rejected' && (
//             <TextField
//               label='Notes'
//               required
//               fullWidth
//               value={notes}
//               onChange={e => setNotes(e.target.value)}
//             ></TextField>
//           )}
//         </DialogContent>
//         <DialogActions sx={{ mt: 10 }}>
//           <Button onClick={toggle}>
//             {/* disabled={loading} */}
//             Cancel
//           </Button>
//           <LoadingButton
//             loading={loading}
//             onClick={UpdateBusinessStatus}
//             sx={{
//               backgroundColor: '#007167',
//               color: '#fff',
//               '&:hover': {
//                 backgroundColor: '#007167',
//                 color: '#fff'
//               }
//             }}
//           >
//             Update Status
//           </LoadingButton>
//         </DialogActions>
//       </Dialog>
//     </>
//   )
// }
// export default BusinessStatusDialog

import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  MenuItem,
  Select,
  TextField
} from '@mui/material'
import CloseIcon from 'mdi-material-ui/Close'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { axiosInstance } from 'src/network/adapter'
import { ApiEndPoints } from 'src/network/endpoints'
import * as yup from 'yup'
const validationSchema = yup.object().shape({
  status: yup.string().required('Status is required'),
  notes: yup
    .string()
    .trim()
    .when('status', {
      is: 'rejected',
      then: schema => schema.required('Notes is required')
    })
})

const DialogFormWithdrawal = ({ isOpen, toggle, businessID, businessStatus, getUBusinessId }) => {
  const [loading, setLoading] = useState(false)
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors }
  } = useForm({
    defaultValues: {
      status: '',
      notes: ''
    },
    resolver: yupResolver(validationSchema),
    mode: 'onChange'
  })

  const status_watch = watch('status')

  useEffect(() => {
    if (isOpen) {
      setLoading(false)
      reset({
        status: '',
        notes: ''
      })
    }
  }, [isOpen, reset])
  const onSubmit = data => {
    let payload = {
      company_status: data.status
    }
    if (data.status === 'rejected') {
      payload = {
        ...payload,
        description: data.notes
      }
    }
    setLoading(true)
    axiosInstance
      .put(ApiEndPoints.MY_BUSINESS.business_update(businessID), payload)
      .then(response => {
        toast.success(response.data.message)
        setLoading(false)
        getUBusinessId()
        toggle()
      })
      .catch(error => {
        toast.success(error.response.data.message)
        console.error(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleStatusChange = e => {
    const status = e.target.value
    setValue('status', status)
  }

  return (
    <Dialog open={isOpen} onClose={toggle} fullWidth maxWidth='sm' scroll='paper'>
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box>Business Detail Status</Box>
        <IconButton aria-label='close' onClick={toggle} sx={{}}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ pb: 8, px: { sx: 8, sm: 15 }, pt: { xs: 8, sm: 12.5 } }}>
        <form id='withdrawal-form' onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <FormControl fullWidth sx={{ mb: 4 }}>
              <FormLabel htmlFor='status'>Status</FormLabel>
              <Controller
                name='status'
                control={control}
                render={({ field: { value } }) => (
                  <Select size='small' value={value} onChange={handleStatusChange} error={Boolean(errors.status)}>
                    <MenuItem value={'approved'}>Approved</MenuItem>
                    <MenuItem value={'rejected'}>Rejected</MenuItem>
                  </Select>
                )}
              />
              {errors.status && <FormHelperText sx={{ color: 'error.main' }}>{errors.status.message}</FormHelperText>}
            </FormControl>
          </Box>
          {status_watch === 'rejected' && (
            <Box>
              <FormControl fullWidth sx={{ mb: 4 }}>
                <FormLabel htmlFor='notes' error={Boolean(errors.notes)}>
                  Notes
                </FormLabel>
                <Controller
                  name='notes'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      size='small'
                      error={Boolean(errors.notes)}
                      id='notes'
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
                {errors.notes && <FormHelperText sx={{ color: 'error.main' }}>{errors.notes.message}</FormHelperText>}
              </FormControl>
            </Box>
          )}
        </form>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
        <LoadingButton size='large' type='submit' form='withdrawal-form' variant='contained' loading={loading}>
          Submit
        </LoadingButton>
        <Button size='large' variant='outlined' onClick={toggle}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogFormWithdrawal
