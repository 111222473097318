// ** React Imports
import { useEffect } from 'react'

// ** Next Imports
import { useNavigate, useLocation } from 'react-router-dom'

// ** Hooks Import
import { useAuth } from 'src/hooks/useAuth'

const GuestPath = ['/login', '/forgot-password', '/varify-otp', '/reset-password']

const AuthGuard = props => {
  const { children, fallback } = props
  const auth = useAuth()

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const currentPath = location.pathname + location.search + location.hash
    if (auth.user === null) {
      if (!GuestPath.includes(location.pathname)) {
        navigate(`/login?returnUrl=${currentPath}`)
      }
    } else {
      window.scrollTo(0, 0);
      if (
        location.pathname === '/login' ||
        location.pathname === '/forgot-password' ||
        location.pathname === '/varify-otp' ||
        location.pathname === '/reset-password'
      ) {
        navigate('/login')
      }
    }
  }, [auth.user, location.hash, location.pathname, location.search, navigate])

  if (!GuestPath.includes(location.pathname) && (auth.loading || auth.user === null)) {
    return fallback
  }

  return children
}

export default AuthGuard
// useEffect(
//   () => {
//     const currentPath = location.pathname + location.search + location.hash
//     if (auth.user === null && !window.localStorage.getItem('userData')) {
//       if (!GuestPath.includes(location.pathname)) {
//         if (currentPath !== '/') {
//           navigate(`/login?returnUrl=${currentPath}`)
//         } else {
//           navigate('/login')
//         }
//       }
//     }
//   },
//   // eslint-disable-next-line react-hooks/exhaustive-deps
//   []
// )
