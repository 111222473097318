// ** React Imports
import { createContext, useEffect, useState } from 'react'

// ** Next Import
import { useNavigate, useSearchParams } from 'react-router-dom'

// ** Axios
import axios from 'axios'

// ** Config
import authConfig from '../configs/auth'
import { ApiEndPoints } from 'src/network/endpoints'
import { axiosInstance } from 'src/network/adapter'
import FallbackSpinner from 'src/@core/components/spinner'

// ** Defaults
const defaultProvider = {
  unReadNotificationCount: 0,
  setUnReadNotificationCount: 0,
  user: null,
  loading: true,
  setUser: () => null,
  setLoading: () => Boolean,
  isInitialized: false,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  setIsInitialized: () => Boolean,
  register: () => Promise.resolve(),
  getUnReadNotificationCount: () => Promise.resolve()
}
const AuthContext = createContext(defaultProvider)

const AuthProvider = ({ children }) => {
  // ** States
  const [user, setUser] = useState(defaultProvider.user)
  const [loading, setLoading] = useState(defaultProvider.loading)
  const [isInitialized, setIsInitialized] = useState(defaultProvider.isInitialized)
  const [unReadNotificationCount, setUnReadNotificationCount] = useState(defaultProvider.unReadNotificationCount)

  console.log('DEBUG USWER', user)

  // ** Hooks
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const getUnReadNotificationCount = () => {
    axiosInstance
      .get(ApiEndPoints.NOTIFICATIONS.get_unread_notifications_count)
      .then(response => response.data)
      .then(response => {
        console.log('getUnReadNotificationCount', response)
        setUnReadNotificationCount(response?.count)
      })
      .catch(error => {
        console.log('error', error)
      })
  }

  useEffect(() => {
    const initAuth = async () => {
      setIsInitialized(true)
      const token = localStorage.getItem('token')

      if (token) {
        setLoading(true)
        await axiosInstance
          .get(ApiEndPoints.AUTH.me, {})
          .then(async response => {
            // console.log("ME Response",response);
            setLoading(false)
            setUser(response?.data)
          })
          .catch(error => {
            localStorage.removeItem('userData')
            localStorage.removeItem('refreshToken')
            localStorage.removeItem('token')
            setUser(null)

            setLoading(false)
          })
      } else {
        setLoading(false)
      }
    }
    initAuth()
  }, [])

  const handleLogin = (params, errorCallback) => {
    //debugger
    axiosInstance
      .post(ApiEndPoints.AUTH.login, params)
      .then(async res => {
        window.localStorage.setItem(authConfig.storageTokenKeyName, res.data.token)
      })
      .then(() => {
        const token = localStorage.getItem(authConfig.storageTokenKeyName)
        axiosInstance
          .get(ApiEndPoints.AUTH.me, { headers: { Authorization: `Bearer ${token}` } })
          .then(async response => {
            const returnUrl = searchParams.get('returnUrl')
            console.log('UserData Login', response?.data)
            setUser(response?.data)
            await window.localStorage.setItem('userData', JSON.stringify(response.data))
            const redirectURL = returnUrl && returnUrl !== '/' ? returnUrl : '/'
            if (returnUrl) {
              navigate(redirectURL)
            } else {
              navigate(redirectURL)
            }
          })
      })
      .catch(err => {
        console.error('error: ', err)
        if (errorCallback) errorCallback(err)
      })
  }

  // const handleLogin = async (params, errorCallback) => {
  //   await axiosInstance
  //     .post(ApiEndpoints.AUTH.login, params)
  //     .then(response => response.data)
  //     .then(async response => {
  //       // setUser(response.user)
  //       localStorage.setItem(authConfig.storageTokenKeyName, response.data.token)
  //       setUser(response.user)
  //       navigate()
  //     })
  //     .catch(err => {
  //       if (errorCallback) errorCallback(err)
  //     })
  // }

  const handleLogout = () => {
    setUser(null)
    setIsInitialized(false)
    window.localStorage.removeItem('userData')
    window.localStorage.removeItem(authConfig.storageTokenKeyName)
    navigate('/login')
  }

  const handleRegister = (params, errorCallback) => {
    axios
      .post(authConfig.registerEndpoint, params)
      .then(res => {
        if (res.data.error) {
          if (errorCallback) errorCallback(res.data.error)
        } else {
          handleLogin({ email: params.email, password: params.password })
        }
      })
      .catch(err => (errorCallback ? errorCallback(err) : null))
  }

  const values = {
    user,
    loading,
    setUser,
    setLoading,
    isInitialized,
    setIsInitialized,
    login: handleLogin,
    logout: handleLogout,
    register: handleRegister,
    unReadNotificationCount,
    setUnReadNotificationCount,
    getUnReadNotificationCount: getUnReadNotificationCount
  }

  return <AuthContext.Provider value={values}>{loading ? <FallbackSpinner /> : children}</AuthContext.Provider>
}

export { AuthContext, AuthProvider }
