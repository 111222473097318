import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import ImageIcon from '@mui/icons-material/Image'
import ArticleIcon from '@mui/icons-material/Article'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'

export const ASSETS_BASE_URL = 'https://d1gjmth0ov0m4e.cloudfront.net'

export const DefaultPaginationSettings = {
  ROW_HEIGHT: 50,
  ROWS_PER_PAGE: 10,
  ROWS_PER_PAGE_OPTIONS: [10, 20, 50, 100]
}

export const getFileTypeIcon = fileType => {
  switch (fileType) {
    case 'pdf':
      return <PictureAsPdfIcon />
    case 'png':
    case 'jpg':
    case 'jpeg':
      return <ImageIcon />
    case 'doc':
    case 'docx':
      return <ArticleIcon />
    default:
      return <InsertDriveFileIcon />
  }
}

export const getFileType = filePath => {
  const extension = filePath?.split('.').pop().toLowerCase()
  return extension
}
