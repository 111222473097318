import React, { Suspense, useEffect, useState } from 'react'
import { Routes, Route, Outlet, Navigate } from 'react-router-dom'
import FallbackSpinner from './@core/components/spinner'

import AuthGuard from 'src/@core/components/auth/AuthGuard'
import UserLayout from './layouts/UserLayout'
import BlankLayout from './@core/layouts/BlankLayout'
import BlankLayoutWithAppBar from './@core/layouts/BlankLayoutWithAppBar'
import AclGuard from './@core/components/auth/AclGuard'
import { defaultACLObj } from './configs/acl'
import BusinessDetails from './pages/apps/investByBusiness/businessDetails'
import { useAuth } from './hooks/useAuth'

import { onMessage } from 'firebase/messaging'
import { getNotificationPermission, messaging } from './notification/firebase'
import toast from 'react-hot-toast'
import { Avatar, Box, Paper, Typography } from '@mui/material'
import InboxImage from 'src/assets/images/inbox-mail.png'

const LoginPage = React.lazy(() => import('./pages/login'))
const HomePage = React.lazy(() => import('./pages/home'))
const ForgotPasswordV1 = React.lazy(() => import('./pages/pages/auth/forgot-password-v1'))
const OTPVerification = React.lazy(() => import('./pages/pages/auth/verification'))
const ResetPassword = React.lazy(() => import('./pages/pages/auth/reset-password-v1'))

/** Dashboards Routes */
const DashboardCRMPage = React.lazy(() => import('./pages/dashboards/crm'))
const RegisterList = React.lazy(() => import('./pages/apps/register'))
const RegisterUserDetails = React.lazy(() => import('./pages/apps/register/registerUserDetails'))
const SellerListPage = React.lazy(() => import('./pages/apps/seller'))
const SellerDetails = React.lazy(() => import('./pages/apps/seller/sellerDetails'))
const WithDrawPage = React.lazy(() => import('./pages/apps/withdrawal'))
const SellerQuestion = React.lazy(() => import('./pages/apps/sellerQuestions'))
const BuyRequestList = React.lazy(() => import('./pages/apps/buyRequest'))
const AdminBankAccount = React.lazy(() => import('./pages/apps/adminBankAccount'))
const Notification = React.lazy(() => import('./pages/apps/notification'))
const Comment = React.lazy(() => import('./pages/apps/comment'))
const HoldingChangesRequest = React.lazy(() => import('./pages/apps/holdingChangesRequest'))
const InvestByCompanies = React.lazy(() => import('./pages/apps/investByBusiness'))
const OTPContent = React.lazy(() => import('./pages/apps/otpContent'))

/** Other routes */
const UserProfilePage = React.lazy(() => import('./pages/user-profile/profile'))

/** PAGES */
const LoginWithAppBarPage = React.lazy(() => import('./pages/pages/auth/login-with-appbar'))

const Page401 = React.lazy(() => import('./pages/401'))
const Page404 = React.lazy(() => import('./pages/404'))

function App() {
  const aclAbilities = defaultACLObj

  const { user, getUnReadNotificationCount } = useAuth()
  console.log('DEBUG user 123', user)

  console.log('DEBUG user', user)

  const [isTokenFound, setTokenFound] = useState(false)

  useEffect(() => {
    if (user) {
      getNotificationPermission(setTokenFound)
      onMessage(messaging, payload => {
        getUnReadNotificationCount()
        console.log('Message received.', payload)
        toast.custom(
          <Paper elevation={8} sx={{ border: '1px solid #007167', padding: 2 }}>
            <Box display={'flex'} justifyContent={'space-between'} gap={1}>
              <Box>
                <Avatar src={InboxImage} alt={'InboxImage'} variant='square' sx={{ height: '30px', width: '30px' }} />
              </Box>
              <Box>
                <Typography color={'#000'}>{payload.notification.title}</Typography>
                <Typography color={'#000'}>{payload.notification.body}</Typography>
              </Box>
              <Box>
                <Typography sx={{ fontSize: '12px' }}>Just Now</Typography>
              </Box>
            </Box>
          </Paper>,
          {
            style: {
              border: '1px solid #007167',
              marginTop: '50px',
              borderRadius: '10px'
            }
          }
        )
      })
    }
  }, [user])
  

  return (
    <Suspense fallback={<FallbackSpinner />}>
      <AuthGuard fallback={<FallbackSpinner />}>
        <AclGuard aclAbilities={aclAbilities}>
          <Routes>
            <Route
              element={
                <BlankLayout>
                  <Outlet />
                </BlankLayout>
              }
            >
              <Route path='/login' element={<LoginPage />} />
              <Route path='/forgot-password' element={<ForgotPasswordV1 />} />
              <Route path='/verify-otp' element={<OTPVerification />} />
              <Route path='/reset-password' element={<ResetPassword />} />
              <Route path='/401' element={<Page401 />} />
              <Route path='/404' element={<Page404 />} />
            </Route>

            <Route
              element={
                <BlankLayoutWithAppBar>
                  <Outlet />
                </BlankLayoutWithAppBar>
              }
            >
              <Route path='/pages/auth/login-with-appbar' element={<LoginWithAppBarPage />} />
            </Route>

            <Route
              path='/'
              element={
                <UserLayout>
                  <Outlet />
                </UserLayout>
              }
            >
              <Route path='' element={<HomePage />} />
              <Route path='dashboards/crm' element={<DashboardCRMPage />} />
              <Route path='apps/register' element={<RegisterList />} />
              <Route path='apps/register/details/:userId' element={<RegisterUserDetails />} />
              <Route path='apps/seller' element={<SellerListPage />} />
              <Route path='apps/seller/details/:userId' element={<SellerDetails />} />
              <Route path='apps/withdraw' element={<WithDrawPage />} />
              <Route path='seller/questions' element={<SellerQuestion />} />
              <Route path='buy/request' element={<BuyRequestList />} />
              <Route path='admin/bank-details' element={<AdminBankAccount />} />
              <Route path='user-profile/profile/' element={<UserProfilePage />} />
              <Route path='admin/notification' element={<Notification />} />
              <Route path='admin/comment' element={<Comment />} />
              <Route path='admin/holding/change/request/list' element={<HoldingChangesRequest />} />
              <Route path='admin/my/companies/list' element={<InvestByCompanies />} />
              <Route path='admin/my/companies/list/details/:id' element={<BusinessDetails />} />
              <Route path='admin/otp-content' element={<OTPContent />} />
            </Route>

            <Route path='/version' element={<>{'v2.0.18'}</>} />
            <Route path='*' element=<Navigate to='/404' /> />
          </Routes>
        </AclGuard>
      </AuthGuard>
    </Suspense>
  )
}

export default App
