// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getMessaging, getToken } from 'firebase/messaging'
import { axiosInstance } from '../network/adapter'
import { ApiEndPoints } from '../network/endpoints'
// import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: 'AIzaSyAJvQCL5DQhrCWwIT758J8L9fwRx5y8dSU',
  authDomain: 'thanawi-a5b0a.firebaseapp.com',
  projectId: 'thanawi-a5b0a',
  storageBucket: 'thanawi-a5b0a.appspot.com',
  messagingSenderId: '484609411345',
  appId: '1:484609411345:web:79836c7bd2d3e434a6a4b0',
  measurementId: 'G-QG7L1BJ47E'
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
export const messaging = getMessaging(app)
// const analytics = getAnalytics(app);

export const getNotificationPermission = async setTokenFound => {
  // debugger;
  const permission = await Notification.requestPermission()
  console.log('permission', permission)
  if (permission === 'granted') {
    setTokenFound(true)
    const token = await getToken(messaging, {
      vapidKey: 'BGRDvfAhCI0g9HDaviUF_z_XoM9O97_uY2QllMux8YJYh4ZoTRHkzWlTUMbyeHOFvqYJRC1fuQ7EqYbVF8itkFQ'
    })
    console.log('firebase token', token)
    if (token) {
      axiosInstance
        .post(ApiEndPoints.NOTIFICATIONS.sent_fcm_token, {
          type: 'admin',
          token: token
        })
        .then(response => response.data)
        .then(response => {
          console.log('DEBUG fcmToken', response)
        })
        .catch(error => {
          console.error()
        })
        .finally(() => {})
    }
  } else {
    setTokenFound(false)
  }
}

// export const getNotificationPermission = async (setTokenFound) => {
//   await Notification.requestPermission().then((permission) => {
//     console.log("permission", permission);
//     if(permission === "denied"){
//       setTokenFound(false);
//     }
//     else{
//       setTokenFound(true);
//     }
//   });
// };
