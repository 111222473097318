import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Box } from '@mui/material'

function ShareHolderTable({ shareHolderData }) {
  console.log('shareHolderData', shareHolderData)

  return (
    <>
      <TableContainer component={Box} border={'2px solid rgba(80, 80, 80, 0.22)'} borderRadius={5} p={2}>
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell
                align='left'
                sx={{ color: '#007167', fontSize: '16px !important', fontWeight: '800 !important' }}
              >
                ID
              </TableCell>
              <TableCell
                align='left'
                sx={{ color: '#007167', fontSize: '16px !important', fontWeight: '800 !important' }}
              >
                Name
              </TableCell>
              <TableCell
                align='left'
                sx={{ color: '#007167', fontSize: '16px !important', fontWeight: '800 !important' }}
              >
                Shares
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {shareHolderData.length > 0 &&
              shareHolderData?.map((row, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell align='left' sx={{ fontWeight: '800 !important' }}>{`${index + 1}`}</TableCell>
                    <TableCell align='left' sx={{ fontWeight: '800 !important' }}>
                      {row.name}
                    </TableCell>
                    <TableCell align='left' sx={{ fontWeight: '800 !important' }}>
                      {row.sharesCount}
                    </TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
export default ShareHolderTable
